import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useQueryGetCouponsByTenantID } from "../../hooks/useQuery";
import Footer from "../organisms/footer";

const FavoriteCouponPage: FC = () => {
  const navigate = useNavigate();
  const { data: getCouponsByTenantID } = useQueryGetCouponsByTenantID();

  return (
    <>
      <div className="min-h-screen bg-#FAF6EF font-hiragino">
        <div className="flex flex-wrap">
          {getCouponsByTenantID?.coupon.map((coupon) => {
            return (
              <>
                <div
                  onClick={() => {
                    navigate("/coupons/1");
                  }}
                  className="w-6/12 mt-5"
                >
                  <div className="w-10/12 h-50 mx-auto bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                    <div className="bg-gray-300 rounded-t-lg h-18 pt-12 text-center">
                      No Image
                    </div>
                    <p className="mt-3 mb-0 ml-3 font-semibold text-gray-700 dark:text-gray-400">
                      {coupon.couponName}
                    </p>
                    <p className="mt-3 ml-3 p-2 w-18 leading-3 text-base font-normal text-white rounded-md bg-red-400">
                      50%OFF
                    </p>
                  </div>
                </div>
              </>
            );
          })}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default FavoriteCouponPage;
