import { Link } from "react-router-dom";

const SearchSelectionBar = ({
  searchSelection,
}: {
  searchSelection?: string;
}) => {
  return (
    <>
      <div className="z-100 fixed t-49 l-0 flex flex-col gap-16px box-border w-full px-16px bg-#FAF6EF">
        <div className="relative flex justify-center h-56px pb-16px">
          <div
            className="relative w-1/2 h-24p p-16px text-center"
            style={{
              WebkitTapHighlightColor: "transparent", // iOSでのタップハイライトを無効化
              outline: "none", // タップ時のアウトラインを無効化
            }}
          >
            <Link
              to="/"
              className={`no-underline text-16px font-hiragino  ${searchSelection == "distance" ? "text-#F39828 font-semibold" : "text-secondary"}`}
            >
              距離から探す
            </Link>
            {searchSelection == "distance" && (
              <div className="w-full absolute left-0 bottom-0 h-4px rounded-999px bg-#F39828"></div>
            )}
          </div>
          <div
            className="relative w-1/2 h-24p p-16px text-center"
            style={{
              WebkitTapHighlightColor: "transparent", // iOSでのタップハイライトを無効化
              outline: "none", // タップ時のアウトラインを無効化
            }}
          >
            <Link
              to="/coupons/recommend"
              className={`no-underline text-16px font-hiragino  ${searchSelection == "recommend" ? "text-#F39828 font-semibold" : "text-secondary"}`}
            >
              あなたにおすすめ
            </Link>
            {searchSelection == "recommend" && (
              <div className="w-full absolute left-0 bottom-0 h-4px rounded-999px bg-#F39828"></div>
            )}
          </div>
        </div>
      </div>
      <div className="h-56px pb-16px bg-#FAF6EF"></div>
    </>
  );
};

export default SearchSelectionBar;
