import {
  SDKInstance,
  createSDKInstance,
  createApiBackend,
  createAppBackend,
} from "@pocketsign/in-app-sdk";
import { api } from "./api";

export let sdk: SDKInstance;

export async function initializeSDK() {
  const res = await api.getTenant({});

  const backend =
    import.meta.env.VITE_P8N_BACKEND_MODE === "API"
      ? createApiBackend({
          accessToken: import.meta.env.VITE_DEV_P8N_ACCESS_TOKEN,
          baseUrl: "https://internal.core.mock.p8n.dev",
        })
      : createAppBackend();

  sdk = await createSDKInstance({
    serviceId: res.tenant?.serviceId || "",
    backend,
  });
}
