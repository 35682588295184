import { FC } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";

import { useQueryGetShopsByTenantIDSortedByDistance } from "../../hooks/useQuery";
import Footer from "../organisms/footer";
import { OutletContext } from "../templates/createAndGetUserLayout";
import Header from "../organisms/header";
import AddressBar from "../organisms/addressBar";
import Loading from "../organisms/loading";

const ShopListPage: FC = () => {
  const { user } = useOutletContext<OutletContext>();
  const navigate = useNavigate();
  const { data: getShopsByTenantIDSortedByDistance, isLoading } =
    useQueryGetShopsByTenantIDSortedByDistance(user.subscriptionId);

  if (isLoading) {
    // ローディング画面を表示
    return (
      <div className="min-h-screen flex justify-center items-center bg-#FAF6EF">
        <Loading></Loading>
      </div>
    );
  }

  return (
    <>
      <div className="min-h-screen bg-#FAF6EF font-hiragino">
        <Header title="ショップ" isShowBackArrow={false}></Header>
        <div className="flex flex-col gap-16px">
          <AddressBar></AddressBar>
          <div className="flex flex-wrap justify-start gap-16px w-full max-w-[390px] box-border mx-auto px-16px pb-200px ">
            {getShopsByTenantIDSortedByDistance?.shops.length == 0 ? (
              <div className="py-48px w-full">
                <div className="flex justify-center ">
                  <span className="material-symbols-rounded text-[80px] text-#9CA3AF80">
                    storefront
                  </span>
                </div>
                <div className="text-14px text-tertiary text-center leading-5">
                  ショップが登録されていません
                </div>
              </div>
            ) : (
              getShopsByTenantIDSortedByDistance?.shops.map((shop) => {
                return (
                  <>
                    <div
                      onClick={() => {
                        navigate(`/shops/${shop.shop?.id}`);
                      }}
                      className="relative h-196px bg-#FFF rounded-12px shadow-DropShadowHight"
                      style={{
                        width: "calc((100% - 16px) / 2)",
                      }}
                    >
                      <div className="flex justify-end w-full h-26px p-4px absolute">
                        <div className="ml-auto mr-10px inline-block h-18px px-8px py-4px bg-#FFF rounded-20px border-solid border-1.5px border-#F39828 text-12px text-center font-bold text-#F39828 font-hiragino leading-150">
                          {shop.distance >= 1000
                            ? `+${
                                (Number(shop.distance) / 1000) % 1 === 0
                                  ? (Number(shop.distance) / 1000).toFixed(0) // 小数点第一位が0なら整数表示
                                  : (Number(shop.distance) / 1000).toFixed(1)
                              }km` // それ以外は小数点第一位まで表示
                            : `+${Math.floor(Number(shop.distance) / 100) * 100}m`}
                        </div>
                      </div>
                      <div className="h-120px">
                        <img
                          src={shop.shop?.imageBase64}
                          alt={shop.shop?.name}
                          className="w-full h-120px object-cover rounded-t-12px" // 画像を全体に表示し、オブジェクトカバーでサイズに応じてトリミング
                        />
                      </div>
                      <div className="flex flex-col gap-6px w-151px h-60px px-10px py-8px">
                        <div className="h-24px text-16px text-#111827 font-hiragino truncate">
                          {shop.shop?.name}
                        </div>
                      </div>
                    </div>
                  </>
                );
              })
            )}
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default ShopListPage;
