import { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { requestPermission } from "@pocketsign/in-app-sdk";
import { sdk } from "../../lib/sdk";
import Loading from "../organisms/loading";

function RequestPermissionLayout() {
  const [needAgree, setNeedAgree] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const requestPermissions = async () => {
    const response = await requestPermission(sdk, [
      {
        resourceId: "32df0061-de96-49a6-99d3-764497e64ed6", // 市区町村までの住所
        verbs: [
          {
            verb: "read" as const,
            term: "always", // term が必要
          },
        ],
      },
      {
        resourceId: "5419c333-2863-4d57-9272-5f6c6c8989ec", // 住所
        verbs: [
          {
            verb: "read" as const,
            term: "always", // term が必要
          },
        ],
      },
      {
        resourceId: "5ac941bf-88f3-47e3-b2e7-3bb0522aa14b", // 性別
        verbs: [
          {
            verb: "read" as const,
            term: "always", // term が必要
          },
        ],
      },
      {
        resourceId: "612c6a3c-212a-402e-8c89-792a4b3e7889", // 生年月日
        verbs: [
          {
            verb: "read" as const,
            term: "always", // term が必要
          },
        ],
      },
      {
        resourceId: "3441d71b-8b11-44aa-886e-c46901f3c910", // プッシュ通知を送るための権限
        verbs: [
          {
            verb: "invoke" as const,
            term: "always", // term が必要
          },
        ],
      },
    ]);

    if (response.result !== "agree") {
      console.error(`failed to request permission: ${response.result}`);
      setNeedAgree(true);
      return;
    }

    setIsLoading(false);
  };

  useEffect(() => {
    requestPermissions();
  }, []);

  if (isLoading) {
    return (
      <div className="min-h-screen flex justify-center items-center bg-#FAF6EF">
        <Loading></Loading>
      </div>
    );
  }

  if (needAgree) {
    return <p>権限がありません。</p>;
  }

  return <Outlet />;
}

export default RequestPermissionLayout;
