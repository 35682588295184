import { useNavigate, useLocation } from "react-router-dom";
import { MaterialSymbol } from "react-material-symbols";
import "react-material-symbols/rounded";

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const firstPathSegment =
    location.pathname.split("/").filter(Boolean).shift() || "";

  return (
    <>
      <footer className="h-74px fixed inset-x-0 bottom-0 flex justify-between px-16px pt-4px pb-16px bg-#FFF border-t-solid border-t-1px border-t-disableText">
        <div
          className={`w-68.6px h-54px pb-12px ${
            firstPathSegment == "" ||
            firstPathSegment == "recommend" ||
            firstPathSegment == "favorite_coupons" ||
            firstPathSegment == "coupons" ||
            firstPathSegment == "transactions"
              ? "text-#F39828 font-semibold"
              : "text-secondary"
          }`}
          style={{
            WebkitTapHighlightColor: "transparent", // iOSでのタップハイライトを無効化
            outline: "none", // タップ時のアウトラインを無効化
          }}
        >
          <div
            onClick={() => {
              navigate("/");
            }}
            className="pt-15px px-23px"
          >
            {firstPathSegment == "" ||
            firstPathSegment == "recommend" ||
            firstPathSegment == "favorite_coupons" ||
            firstPathSegment == "coupons" ||
            firstPathSegment == "transactions" ? (
              <MaterialSymbol
                icon="confirmation_number"
                size={24}
                fill={true}
                color="#F39828"
              />
            ) : (
              <span className="material-symbols-rounded text-[24px]">
                confirmation_number
              </span>
            )}
          </div>
          <div className="text-12px text-center font-hiragino">クーポン</div>
        </div>
        <div
          className={`w-68.6px h-54px pb-12px ${
            firstPathSegment == "shops"
              ? "text-#F39828 font-semibold"
              : "text-secondary"
          }`}
          style={{
            WebkitTapHighlightColor: "transparent", // iOSでのタップハイライトを無効化
            outline: "none", // タップ時のアウトラインを無効化
          }}
        >
          <div
            onClick={() => {
              navigate("/shops");
            }}
            className="pt-15px px-23px"
          >
            {firstPathSegment == "shops" ? (
              <MaterialSymbol
                icon="storefront"
                size={24}
                fill={true}
                color="#F39828"
              />
            ) : (
              <span className="material-symbols-rounded text-[24px]">
                storefront
              </span>
            )}
          </div>
          <div className="text-12px text-center font-hiragino">ショップ</div>
        </div>
        <div
          className={`w-68.6px h-54px pb-12px ${
            firstPathSegment == "settings"
              ? "text-#F39828 font-semibold"
              : "text-secondary"
          }`}
          style={{
            WebkitTapHighlightColor: "transparent", // iOSでのタップハイライトを無効化
            outline: "none", // タップ時のアウトラインを無効化
          }}
        >
          <div
            onClick={() => {
              navigate("/settings");
            }}
            className="pt-15px px-23px"
          >
            {firstPathSegment == "settings" ? (
              <MaterialSymbol
                icon="settings"
                size={24}
                fill={true}
                color="#F39828"
              />
            ) : (
              <span className="material-symbols-rounded text-[24px]">
                settings
              </span>
            )}
          </div>
          <div className="text-12px text-center font-hiragino">設定</div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
