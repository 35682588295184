import { FC, useEffect, useState } from "react";
import Footer from "../organisms/footer";
import { useOutletContext } from "react-router-dom";
import { OutletContext } from "../templates/createAndGetUserLayout";
import { sdk } from "../../lib/sdk";
import { openServiceSettings } from "@pocketsign/in-app-sdk";
import Header from "../organisms/header";

const SettingsPage: FC = () => {
  const { user } = useOutletContext<OutletContext>();
  const [address, setAddress] = useState("");
  const [gender, setGender] = useState("");
  const [birthday, setBirthday] = useState("");
  const formatDate = (dateString: string): string => {
    // "0001-01-01 09:18:59 +0918 LMT" から "0001-01-01" の部分を抽出
    const datePart = dateString.split(" ")[0];

    // Dateオブジェクトに変換
    const date = new Date(datePart);

    // 無効な日付をチェック
    if (isNaN(date.getTime())) {
      return "Invalid Date";
    }

    // 年月日のフォーマット
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long", // 'short' や 'numeric' に変更可能
      day: "numeric",
    };

    // 'ja-JP' を使用して日本語フォーマットに
    return date.toLocaleDateString("ja-JP", options);
  };

  useEffect(() => {
    setAddress(user.address);

    if (user.gender == "male") {
      setGender("男");
    } else if (user.gender == "female") {
      setGender("女");
    } else {
      setGender("その他");
    }

    setBirthday(formatDate(user.birthday));
  }, []);

  return (
    <>
      <div className="min-h-screen bg-#FAF6EF font-hiragino">
        <Header title="設定" isShowBackArrow={false}></Header>
        <div className="min-h-screen bg-#FAF6EF">
          <div className="flex flex-col gap-24px p-16px ">
            <div className="flex flex-col gap-12px">
              <div className="text-14px text-tertiary font-semibold font-hiragino">
                じぶんの情報
              </div>
              <div className="bg-#FFF rounded-16px">
                <div className="flex justify-between py-16px pl-16px pr-8px border-b-solid border-b-1px border-b-lightGray">
                  <div className="w-32px text-16px text-#111827 font-hiragino leading-6">
                    住所
                  </div>
                  <div className="max-w-270px whitespace-normal break-words pr-8px text-14px text-secondary font-hiragino leading-6">
                    {address}
                  </div>
                </div>
                <div className="flex justify-between h-24px py-16px pl-16px pr-8px border-b-solid border-b-1px border-b-lightGray">
                  <div className="text-16px text-#111827 font-hiragino leading-6">
                    性別
                  </div>
                  <div className="whitespace-normal break-words text-14px text-secondary font-hiragino leading-6">
                    {gender}
                  </div>
                </div>
                <div className="flex justify-between h-24px py-16px pl-16px pr-8px">
                  <div className="text-16px text-#111827 font-hiragino leading-6">
                    生年月日
                  </div>
                  <div className="whitespace-normal break-words text-14px text-secondary font-hiragino leading-6">
                    {birthday}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-12px">
              <div className="text-14px text-tertiary font-semibold font-hiragino">
                情報提供と権限付与
              </div>
              <div
                onClick={() => openServiceSettings(sdk)}
                className="flex justify-between h-24px py-16px pl-16px pr-8px bg-#FFF rounded-16px "
              >
                <div className="text-16px text-#111827 font-hiragino leading-6">
                  アプリの設定へ
                </div>
                <div>
                  <span className="material-symbols-rounded text-[24px] text-#4B556380 rounded-999 hover:bg-gray-300 hover:cursor-pointer">
                    arrow_outward
                  </span>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default SettingsPage;
