import { TransportProvider } from "@bufbuild/connect-query";
import { createConnectTransport } from "@bufbuild/connect-web";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { FC, ReactNode } from "react";

type Props = {
  baseUrl: string;
  children: ReactNode;
};

export const Client: FC<Props> = ({ baseUrl, children }) => {
  const transport = createConnectTransport({
    baseUrl,
  });
  const client = new QueryClient();

  return (
    <TransportProvider transport={transport}>
      <QueryClientProvider client={client}>{children}</QueryClientProvider>
    </TransportProvider>
  );
};
