import { useOutletContext } from "react-router-dom";
import { OutletContext } from "../templates/createAndGetUserLayout";

const AddressBar = () => {
  const { user } = useOutletContext<OutletContext>();

  return (
    <>
      <div className="flex flex-col gap-8px box-border w-full max-w-[390px] mx-auto pt-8px px-16px">
        <div className="h-24px text-18px font-semibold text-secondary font-hiragino line-height-135">
          あなたの住所
        </div>
        <div className="whitespace-normal break-words box-border w-full py-8px px-16px rounded-8px bg-#FFF text-14px text-secondary font-hiragino leading-5">
          {user.address}
        </div>
      </div>
    </>
  );
};

export default AddressBar;
