import { FC } from "react";
import { useParams } from "react-router-dom";
import { useQueryGetShopByID } from "../../hooks/useQuery";
import GoogleMapsPicker from "../organisms/google-maps-picker";
import Header from "../organisms/header";
import Loading from "../organisms/loading";
import { openGoogleMaps } from "../../utils/googlemap";

const ShopDetailPage: FC = () => {
  const { shopID } = useParams<{ shopID: string }>();
  const { data: getShopByID, isLoading } = useQueryGetShopByID(shopID || "");

  if (isLoading) {
    return (
      <div className="min-h-screen flex justify-center items-center bg-#FAF6EF">
        <Loading></Loading>
      </div>
    );
  }

  return (
    <>
      <div className="min-h-screen bg-#FAF6EF font-hiragino">
        <Header title={getShopByID?.shop?.name} isShowBackArrow={true}></Header>
        <div className="min-h-screen bg-#FAF6EF p-16px">
          <div className="flex flex-col gap-12px p-16px bg-#FFF rounded-12px">
            <div className="text-16px text-#111827 font-hiragino leading-5 break-all">
              {getShopByID?.shop?.name}
            </div>
            {getShopByID?.shop?.description && (
              <div
                className="text-14px text-#111827 font-hiragino leading-5 break-all"
                dangerouslySetInnerHTML={{
                  __html: getShopByID?.shop?.description.replace(
                    /\n/g,
                    "<br />"
                  ),
                }}
              />
            )}
            <div className="w-full aspect-[4/3] mx-auto rounded-8px overflow-hidden">
              <img
                src={getShopByID?.shop?.imageBase64}
                alt={getShopByID?.shop?.name}
                className="w-full h-full object-cover rounded-8px"
              />
            </div>
            {getShopByID?.shop?.openingHours && (
              <div className="flex flex-col gap-8px">
                <div className="text-14px text-#111827 font-semibold font-hiragino leading-5">
                  営業時間
                </div>
                <div
                  className="text-14px text-#111827 font-hiragino leading-5 break-all"
                  dangerouslySetInnerHTML={{
                    __html: getShopByID?.shop?.openingHours.replace(
                      /\n/g,
                      "<br />"
                    ),
                  }}
                />
              </div>
            )}
            <div className="flex flex-col gap-4px">
              <div className="text-14px text-#111827 font-semibold font-hiragino leading-5">
                住所
              </div>
              <div
                onClick={() => openGoogleMaps(getShopByID?.shop?.address || "")}
                className="text-14px text-#001EFB font-hiragino leading-5 break-all"
              >
                {getShopByID?.shop?.address}
              </div>
              <div className="h-262px">
                <GoogleMapsPicker
                  latitute={getShopByID?.shop?.latitude || 35.68123620000001}
                  longitude={getShopByID?.shop?.longitude || 139.7671248}
                  onChangeLocation={() => {}}
                />
              </div>
            </div>
            {getShopByID?.shop?.phoneNumber && (
              <div className="flex flex-col gap-8px">
                <div className="text-14px text-#111827 font-semibold font-hiragino leading-5">
                  お問合せ
                </div>
                <a
                  href={`tel:${getShopByID.shop.phoneNumber}`}
                  className="flex gap-2px w-116px h-20px pl-8px pr-12px py-6px bg-transparent rounded-12px border-solid border-1px no-underline border-disableText"
                >
                  <div>
                    <span className="material-symbols-rounded text-18px text-#374151CC leading-5.5">
                      call
                    </span>
                  </div>
                  <div className="text-12px text-secondary font-semibold font-hiragino leading-5">
                    {getShopByID?.shop?.phoneNumber}
                  </div>
                </a>
              </div>
            )}
            {getShopByID?.shop?.homepageUrl && (
              <div className="flex flex-col gap-8px">
                <div className="text-14px text-#111827 font-semibold font-hiragino leading-5">
                  ホームページ
                </div>
                <a
                  href={getShopByID?.shop?.homepageUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-14px text-#001EFB font-hiragino leading-5 no-underline cursor-pointer break-all"
                >
                  {getShopByID?.shop?.homepageUrl}
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ShopDetailPage;
